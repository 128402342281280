import React from 'react'
import { Link } from 'gatsby'
import locationIcon from '../images/location.png'
import phoneIcon from '../images/telephone.png'
import mailIcon from '../images/email.png'
import carIcon from '../images/car.png'

const DistributorTile = props => {
    const { distributor } = props;
    return (
        <div class="bg-white py-8 pl-8 pr-24"> 
      
            <a href={distributor.link} target="_blank" class="font-medium uppercase text-primary text-sm md:text-lg 2xl:text-xl">{distributor.name}</a>
            <ul class="w-full">
                {distributor.location &&
                    <li class="pt-5">
                        <a href={distributor.locationLink} target="_blank" class="flex">
                            <img class="w-5 h-full" src={locationIcon} alt={distributor.name} />
                            <span class="pl-4 ">{distributor.location}</span>
                        </a>
                    </li>
                }
                {distributor.number &&
                    <li class="pt-5">
                        <a href={"tel:" + distributor.number} class="flex">
                            <img class="w-5 h-full" src={phoneIcon} alt={distributor.name} />
                            <span class="pl-4 ">{distributor.number}</span>
                        </a>
                    </li>
                }
                {distributor.email &&
                    <li className="pt-5">
                        <a href={"mailto:" + distributor.email} class="flex">
                            <img class="w-5 h-full" src={mailIcon} alt={distributor.name} />
                            <span class="pl-4 ">{distributor.email}</span>
                        </a>
                    </li>
                }
                {distributor.location &&
                    <li className="pt-5">
                        <a href={distributor.locationLink} target="_blank" class="flex">
                            <img class="w-5 h-full" src={carIcon} alt={distributor.name} />
                            <span class="pl-4 ">Get Directions</span>
                        </a>
                    </li>
                }
            </ul>
        </div>
    )
}

export default DistributorTile